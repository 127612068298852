<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="search">
          <el-input
            v-model="formData.title"
            clearable
            placeholder="模块名称/ID"
          />
        </el-form-item>
        <el-form-item prop="state">
          <el-select
            v-model="formData.status"
            placeholder="上架状态"
            clearable
          >
            <el-option
              v-for="(item, index) in appModuleStatesList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleCreateADModule"
        >
          新建模块
        </el-button>
      </template>
    </BHeader>

    <el-table
      :data="appModuleListInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        label="模块ID"
        min-width="100"
      />
      <el-table-column
        prop="key"
        min-width="100"
        label="模块Key"
      />
      <el-table-column
        prop="title"
        min-width="100"
        label="模块名称"
      />
      <el-table-column
        prop="desc"
        min-width="200"
        label="模块描述"
      />
      <el-table-column
        prop="status"
        min-width="100px"
        label="上架状态"
      >
        <template v-slot="{row}">
          {{ row.status === '1' ? '上架' : '下架' }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        width="260"
        label="操作"
      >
        <template v-slot="{row}">
          <BTextButton
            title="新建"
            icon="fa fa-plus"
            @click="handleCreateAD(row)"
          />
          <BTextButton
            title="管理"
            icon="fa fa-wrench"
            @click="handleViewAdList(row)"
          />
          <BTextButton
            title="编辑板块信息"
            icon="fa fa-pencil"
            @click="handleEditADModule(row)"
          />
          <BTextButton
            :loading="row.loading"
            :title="row.status === '1' ? '启用' : '禁用'"
            :icon="`fa ${row.status === '1' ? 'fa-check-circle' : 'fa-ban' }`"
            @click="handleToggleModule(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <!--    <Pagination-->
    <!--      :total="0"-->
    <!--      :page.sync="formData.pageNum"-->
    <!--      :limit.sync="formData.pageSize"-->
    <!--      @pagination="queryModuleList(formData)"-->
    <!--    />-->
  </Layout>
</template>

<script>
import { appModuleStatesList } from '@/utils/selectOptions'
import { toggleGroupStatus } from '@/api/APP'

import { mapState, mapActions } from 'vuex'
export default {
  name: 'ModuleList',
  data () {
    return {
      appModuleStatesList,
      formData: {
        title: '',
        status: '',
        pageNum: 1,
        pageSize: 20
      }
    }
  },
  computed: {
    ...mapState('appManage', ['appModuleListInfo'])
  },
  created () {
    this.queryModuleList(this.formData)
  },
  methods: {
    ...mapActions('appManage', ['queryModuleList']),
    handleCreateAD (row) {
      this.$router.push({
        name: 'AdvertisementEdit',
        query: {
          groupKey: row.key
        }
      })
    },
    handleCreateADModule () {
      this.$router.push({
        name: 'APP_ModuleEdit'
      })
    },
    handleEditADModule (row) {
      this.$router.push({
        name: 'APP_ModuleEdit',
        query: {
          key: row.key
        }
      })
    },
    handleToggleModule (row) {
      if (row.status !== '1') {
        this.$confirm('此操作将启用模块， 是否继续?', '提示', { type: 'warning ' })
          .then(() => {
            this.$set(row, 'loading', true)
            toggleGroupStatus({
              key: row.key,
              status: '1'
            }).then(res => {
              if (res.code === 200) {
                this.$set(row, 'status', '1')
              }
            }).finally(() => {
              this.$set(row, 'loading', false)
            })
          })
      } else {
        this.$confirm('此操作将禁用模块， 是否继续?', '提示', {
          type: 'warning',
          confirmButtonText: '好的',
          showCancelButton: false
        }).then(() => {
          this.$set(row, 'loading', true)
          toggleGroupStatus({
            key: row.key,
            status: '2'
          }).then(res => {
            if (res.code === 200) {
              this.$set(row, 'status', '2')
            }
          }).finally(() => {
            this.$set(row, 'loading', false)
          })
        })
      }
    },
    handleSearch () {
      this.formData.page = 1
      this.queryModuleList(this.formData)
    },
    handleViewAdList (row) {
      console.log(row.key)
      this.$router.push({
        name: 'AdvertisementList',
        query: {
          groupKey: row.key
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

</style>
